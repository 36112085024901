<template>
  <div>
    <PageHeader />
    <b-row class="mb-3">
      <b-col
        cols="12"
        md="8"
      >
        <b-row>
          <b-col>
            <label>{{ $t('bank_accounts.type') }}</label>
            <br />
            <b-form-radio-group
              v-model="selectBankAccountType"
              button-variant="outline-dark"
              buttons
            >
              <b-form-radio
                v-for="(account, index) in bankAccountTypes"
                :key="index"
                :value="account.type"
              >{{ account.name }}</b-form-radio>
            </b-form-radio-group>
          </b-col>
          <b-col cols="5">
            <label>{{ $t('bank_accounts.automatic_status') }}</label>
            <b-select v-model="selectAutomatedStatus">
              <b-form-select-option
                v-for="(opt, key) in automatedStatusOptions"
                :key="key"
                :value="opt.value"
              >{{ opt.text }}
              </b-form-select-option>
            </b-select>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        md="3"
        offset-md="1"
        class="text-right"
      >
        <b-form-group class="d-flex align-items-end justify-content-end">
          <br />
          <day-picker @update="(e) => (selectedDate = e)"></day-picker>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="my-3">
      <b-col
        v-for="(widget , key) in widgets"
        :key="key"
      >
        <b-card class="p-0 m-0 h-100">
          <div class="media">
            <div class="media-body">
              <span class="text-muted text-uppercase font-size-12 font-weight-bold">
                {{ widget.name }}
              </span>
              <h2 class="mb-0">{{ widget.value | money }}</h2>
            </div>
            <div class="align-self-center">
              <span>
                <feather
                  :type="`${widget.icon}`"
                  class="icon-lg align-middle icon-dual-primary"
                  :class="{
                    'icon-dual-success': key === 1,
                    'icon-dual-warning': key === 2,
                }"
                ></feather>
              </span>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-card>
      <div class="row mb-2">
        <div class="col-12 col-md-3 mb-2">
          <h6>
            {{ `${$t('fields.result')} (${filteredList.length})` }}
          </h6>
        </div>
        <div class="col-12 col-md-3 mb-2">
          <b-input-group>
            <b-form-input
              v-model="filterPlayerUsername"
              type="search"
              :placeholder="
                `${$t('fields.search')}... (${$t('user.username')})`
              "
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="col-12 col-md-3 mb-2">
          <b-input-group class="ml-md-2">
            <b-form-input
              v-model="filterId"
              type="search"
              :placeholder="
                `${$t('fields.search')}... (ID)`
              "
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="col-12 col-md-3 mb-2">
          <b-dropdown
            variant="light"
            class="ml-md-2 w-100"
          >
            <template slot="button-content">
              <i class="uil uil-filter"></i>
              <span v-if="isBankAccountSelect">
                {{ filterBankAccount }}
              </span>
              <span v-else>
                {{ $t('bank_accounts.account_name') }}
              </span>
              <i class="uil uil-angle-down"></i>
            </template>

            <b-dropdown-item-button
              v-for="(item, itemI) in bankAccountList"
              :key="itemI"
              @click="filterBankAccount = item.id"
            >
              {{ item.name || '-' }}
            </b-dropdown-item-button>
            <b-dropdown-divider />
            <b-dropdown-item-button @click="filterBankAccount = ''">
              {{ $t('buttons.clear') }}
            </b-dropdown-item-button>
          </b-dropdown>
        </div>
        <!-- </label> -->
      </div>
      <!-- Table -->
      <b-table
        :items="filteredList"
        :fields="fields"
        :busy="isLoading"
        responsive
        show-empty
      >
        <template #cell(id)="data">
          {{ `#${data.value}` }}
        </template>
        <template #cell(transaction_time)="data">
          {{ data.value | datetime }}
        </template>
        <template #cell(amount)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(transaction_description)="data">
          <b-badge variant="light">
            {{ data.value}}
          </b-badge>
        </template>
        <template #cell(is_sync)="data">
          <b-badge :variant="data.value ?'success': 'danger'">
            {{ data.value ?$t('bank_accounts.pair_success'):$t('bank_accounts.pair_failed') }}
          </b-badge>
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #empty="">
          <p class="text-center text-muted">{{
            $t('messages.nothing_here')
          }}</p>
        </template>
      </b-table>
      <!-- pagination -->
      <PaginationInput :per-page="limit" :total="totalRows" @update="(val) => page = val"/>
    </b-card>
  </div>
</template>

<script>
export default {
  page: {
    title: 'Bank account history',
  },
  data() {
    return {
      selectedDate: '',
      selectBankAccountType: '',
      bankAccountTypes: [
        {
          type: 'both',
          name: `${this.$t('bank_accounts.types.deposit_withdraw')}`,
        },
        { type: 'deposit', name: `${this.$t('bank_accounts.types.withdraw')}` },
        {
          type: 'withdraw',
          name: `${this.$t('bank_accounts.types.withdraw')}`,
        },
      ],
      selectAutomatedStatus: 0,
      automatedStatusOptions: [
        { value: 0, text: `${this.$t('bank_accounts.pair_success')}` },
        { value: 1, text: `${this.$t('bank_accounts.pair_failed')}` },
      ],
      summaryData: [],
      totalRows: 0,
      limit: 50,
      page: 1,
      filterBankAccount: null,
      filterId: '',
      filterPlayerUsername: '',
      fields: [
        {
          key: 'id',
          label: '#',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'transaction_time',
          label: `${this.$t('fields.createdAt')}`,
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'account_no',
          label: `${this.$t('bank_accounts.account_no')}`,
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'channel_code',
          label: `${this.$t('bank_accounts.channel')}`,
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'channel_description',
          label: `${this.$t('bank_accounts.channel_detail')}`,
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'remarks',
          label: `${this.$t('fields.remark')}`,
          thStyle: {
            minWidth: '170px',
          },
        },
        {
          key: 'currency',
          label: `${this.$t('bank_accounts.currency')}`,
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'amount',
          label: `${this.$t('fields.amount')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'debit_credit_flag',
          label: `${this.$t('bank_accounts.debit_credit_flag')}`,
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'transaction_code',
          label: `${this.$t('bank_accounts.transaction_code')}`,
          class: 'text-center',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'transaction_description',
          label: `${this.$t('fields.type')}`,
          class: 'text-center',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'is_sync',
          label: `${this.$t('fields.status')}`,
          class: 'text-center',
          thStyle: {
            minWidth: '120px',
          },
        },
      ],
    }
  },
  computed: {
    bankAccountList() {
      return []
    },
    isBankAccountSelect() {
      if (this.filterBankAccount && this.filterBankAccount.length > 0) {
        const found = this.bankAccountList.find(
          (prop) => prop.id === this.filterBankAccount
        )
        return !!found
      }
      return false
    },
    widgets() {
      return [
        {
          icon: 'activity',
          name: `${this.$t('bank_accounts.all_transaction')}`,
          value: 0,
        },
        {
          icon: 'trending-up',
          name: `${this.$t('bank_accounts.total_deposit')}`,
          value: 0,
        },
        {
          icon: 'trending-down',
          name: `${this.$t('bank_accounts.total_withdraw')}`,
          value: 0,
        },
      ]
    },
    filteredList() {
      return []
    },
    isLoading() {
      return false
    },
  },
  watch: {
    selectedDate(val) {
      if (val) {
        this.fetchData()
      }
    },
    page(val) {
      if (val) {
        this.fetchData()
      }
    },
    filterBankAccount(val) {
      if (val) {
        this.fetchData()
      }
    },
    filterId() {
      this.fetchData()
    },
    filterPlayerUsername() {
      this.fetchData()
    },
  },
  created() {
    this.selectBankAccountType = this.bankAccountTypes[0].type
    this.fetchData()
  },
  methods: {
    fetchData() {
      // this.fetch({
      //    page: this.page,
      //       limit: this.limit,
      // })
    },
  },
}
</script>